import { ref, UnwrapRef } from "vue";

/**
 * Standard error definition structure used throughout the application
 */
export interface ErrorDefinition {
  status: number;
  message: string;
}

/**
 * Registry for storing error definitions by category
 */
export interface ErrorRegistry {
  [category: string]: {
    [errorKey: string]: ErrorDefinition;
  };
}

/**
 * Structure of error responses from API
 */
export interface ApiError {
  statusCode?: number;
  message?: string;
}

// Global registry that maintains all application error definitions
const errorRegistry: ErrorRegistry = {};

/**
 * Register a new category of errors with their definitions
 *
 * @example
 * // Import your error definitions
 * import { LOGIN_ERRORS } from "~/components/Page/Login/errors";
 *
 * // Register them at the module level
 * registerErrorCategory("login", LOGIN_ERRORS);
 *
 * @param category - The category name for these errors (e.g. 'login', 'checkout')
 * @param errorDefinitions - Object containing error definitions
 */
export function registerErrorCategory(category: string, errorDefinitions: Record<string, ErrorDefinition>): void {
  errorRegistry[category] = errorDefinitions;
}

/**
 * Composable for handling errors in a consistent way across the application
 *
 * @example
 * // In your handler composable:
 * const { currentError, handleErrorByStatus, clearError } = useErrorHandler<LoginError>("login");
 *
 * // During form submission:
 * try {
 *   await login({ email: email.value, password: password.value });
 *   // Handle success
 * } catch (error) {
 *   handleErrorByStatus(error as ApiError);
 *   // Handle failure
 * }
 *
 * @param category - The category of errors to handle
 */
export function useErrorHandler<ErrorType extends ErrorDefinition>(category: string) {
  // Reactive reference to the current error
  const currentError = ref<ErrorType | null>(null);

  // Get error definitions for this category
  const errorDefinitions = errorRegistry[category] || {};

  /**
   * Handle an API error by matching its status code to registered errors
   *
   * @param error - The API error object typically caught in a try/catch
   */
  const handleErrorByStatus = (error: ApiError) => {
    const status = error?.statusCode;

    // Find matching error by status code
    const matchedError = Object.values(errorDefinitions).find((def) => def.status === status);

    // Fallback error if no match found
    const defaultError: ErrorDefinition = {
      status: 500,
      message: "An unexpected error occurred. Please try again later.",
    };

    // Use matched error or fallback to DEFAULT_ERROR or generic error
    const errorToAssign = matchedError || errorDefinitions.DEFAULT_ERROR || defaultError;

    // Set the current error
    currentError.value = errorToAssign as unknown as UnwrapRef<ErrorType>;
  };

  /**
   * Clear the current error state
   * Typically called before starting a new operation or when dismissing an error
   */
  const clearError = () => {
    currentError.value = null;
  };

  return {
    currentError, // Current error state (null if no error)
    handleErrorByStatus, // Function to process errors by status code
    clearError, // Function to reset the error state
    errorDefinitions, // All defined errors for this category
  };
}
