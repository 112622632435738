export interface LoginError {
  status: number;
  message: string;
}

export const LOGIN_ERRORS: Record<string, LoginError> = {
  CUSTOMER_NOT_ENABLED: {
    status: 423,
    message:
      "It looks like your account hasn't been enabled for online use. Please contact your local branch to enable it.",
  },
  INVALID_CREDENTIALS: {
    status: 403,
    message:
      "We're sorry but we don't recognise the email address or password you've entered. Please check your details and try again.",
  },
  UNAUTHORIZED: {
    status: 401,
    message: "Unauthorized. Please log in again.",
  },
  ACCOUNT_NOT_FOUND: {
    status: 422,
    message: "Account not found. Please check your details and try again.",
  },
  TOO_MANY_REQUESTS: {
    status: 429,
    message: "Too many login attempts. Please try again later.",
  },
  DEFAULT_ERROR: {
    status: 500,
    message: "An unexpected error occurred. Please try again later.",
  },
} as const;
