import { default as _91_46_46_46slug_93xD3Rcww2zSMeta } from "/var/www/apps/storefront-app/pages/[...slug].vue?macro=true";
import { default as about_45us2uZmKFvXwfMeta } from "/var/www/apps/storefront-app/pages/About/about-us.vue?macro=true";
import { default as index3aDaS72uKjMeta } from "/var/www/apps/storefront-app/pages/About/index.vue?macro=true";
import { default as index32ZbzGF4msMeta } from "/var/www/apps/storefront-app/pages/apply-for-credit/index.vue?macro=true";
import { default as indexTGI0avfK6hMeta } from "/var/www/apps/storefront-app/pages/apply-for-credit/success/index.vue?macro=true";
import { default as _91slug_93v8xd2Mf9JVMeta } from "/var/www/apps/storefront-app/pages/Blog/[topic]/[slug].vue?macro=true";
import { default as indexOiLFCWrkUDMeta } from "/var/www/apps/storefront-app/pages/Blog/[topic]/index.vue?macro=true";
import { default as indexkrUyonzpRoMeta } from "/var/www/apps/storefront-app/pages/Blog/index.vue?macro=true";
import { default as _91branch_93fFXCKzmuqoMeta } from "/var/www/apps/storefront-app/pages/Branches/[branch].vue?macro=true";
import { default as indexEHc7b21lkhMeta } from "/var/www/apps/storefront-app/pages/Branches/index.vue?macro=true";
import { default as indexAeHmek2PKeMeta } from "/var/www/apps/storefront-app/pages/Brochures/index.vue?macro=true";
import { default as _91requestId_93zQ0e4zMf54Meta } from "/var/www/apps/storefront-app/pages/Brochures/Requests/[requestId].vue?macro=true";
import { default as indexeRJavjpxiCMeta } from "/var/www/apps/storefront-app/pages/Careers/[info]/index.vue?macro=true";
import { default as indexykxhfFYXLFMeta } from "/var/www/apps/storefront-app/pages/Careers/index.vue?macro=true";
import { default as indexCt1iAG3hPIMeta } from "/var/www/apps/storefront-app/pages/Careers/vacancies/[department]/[role]/index.vue?macro=true";
import { default as indexMVnVfMawWfMeta } from "/var/www/apps/storefront-app/pages/Careers/vacancies/[department]/index.vue?macro=true";
import { default as indexaRW7DFzp0ZMeta } from "/var/www/apps/storefront-app/pages/Careers/vacancies/index.vue?macro=true";
import { default as indexPdo03gPiogMeta } from "/var/www/apps/storefront-app/pages/Cart/index.vue?macro=true";
import { default as indexOjFAU2XZohMeta } from "/var/www/apps/storefront-app/pages/category/[[cat1]]/[[cat2]]/[[cat3]]/index.vue?macro=true";
import { default as indexKHZ8eDdYJuMeta } from "/var/www/apps/storefront-app/pages/checkout-login/index.vue?macro=true";
import { default as indexCs9FeokLYqMeta } from "/var/www/apps/storefront-app/pages/create-account/index.vue?macro=true";
import { default as indexnL5cYa8agqMeta } from "/var/www/apps/storefront-app/pages/create-account/success/index.vue?macro=true";
import { default as _91topic_939vWWQxx86JMeta } from "/var/www/apps/storefront-app/pages/explore/[topic].vue?macro=true";
import { default as indexja73ISUa7gMeta } from "/var/www/apps/storefront-app/pages/forgotten-password/index.vue?macro=true";
import { default as indexUZZHIvdXFhMeta } from "/var/www/apps/storefront-app/pages/get-mobile-app/index.vue?macro=true";
import { default as index2DfmuZJBvvMeta } from "/var/www/apps/storefront-app/pages/Kitchens/[folder]/[...name]/index.vue?macro=true";
import { default as indexHP3x6eoAngMeta } from "/var/www/apps/storefront-app/pages/Kitchens/[folder]/index.vue?macro=true";
import { default as indexFyGjUbKHzZMeta } from "/var/www/apps/storefront-app/pages/Kitchens/index.vue?macro=true";
import { default as indexedZrmX2wEUMeta } from "/var/www/apps/storefront-app/pages/Login/index.vue?macro=true";
import { default as indexonTZWhuKYjMeta } from "/var/www/apps/storefront-app/pages/my-account/address-book/index.vue?macro=true";
import { default as index1neI4BGFR1Meta } from "/var/www/apps/storefront-app/pages/my-account/financial/bank/redirect/index.vue?macro=true";
import { default as indexrWERJce7WHMeta } from "/var/www/apps/storefront-app/pages/my-account/financial/index.vue?macro=true";
import { default as index5GZFt9YPIaMeta } from "/var/www/apps/storefront-app/pages/my-account/financial/pay/failed/index.vue?macro=true";
import { default as indexrilCgyJMHmMeta } from "/var/www/apps/storefront-app/pages/my-account/financial/pay/index.vue?macro=true";
import { default as indexSTvOwujGMhMeta } from "/var/www/apps/storefront-app/pages/my-account/financial/pay/redirect/index.vue?macro=true";
import { default as indexrBGzxJWQiuMeta } from "/var/www/apps/storefront-app/pages/my-account/financial/pay/thank-you/index.vue?macro=true";
import { default as indexcEKrbvOW9zMeta } from "/var/www/apps/storefront-app/pages/my-account/index.vue?macro=true";
import { default as indexOQQso2pygwMeta } from "/var/www/apps/storefront-app/pages/my-account/manage-users/index.vue?macro=true";
import { default as _91slug_93MGejmAvl7ZMeta } from "/var/www/apps/storefront-app/pages/my-account/orders/[slug].vue?macro=true";
import { default as indexxHs7H82iYXMeta } from "/var/www/apps/storefront-app/pages/my-account/orders/index.vue?macro=true";
import { default as _91_token_93wnF2nCkxdoMeta } from "/var/www/apps/storefront-app/pages/order-confirmation/[_token].vue?macro=true";
import { default as indexqMIFbvH9VzMeta } from "/var/www/apps/storefront-app/pages/Pay/index.vue?macro=true";
import { default as _91productHandle_93uWKpkgCB1FMeta } from "/var/www/apps/storefront-app/pages/product/[productHandle].vue?macro=true";
import { default as indexjtH4pz6xWLMeta } from "/var/www/apps/storefront-app/pages/reset-password/index.vue?macro=true";
import { default as indexTkUHtq1kRrMeta } from "/var/www/apps/storefront-app/pages/set-password/index.vue?macro=true";
import { default as StoryblokU1DVT196ENMeta } from "/var/www/apps/storefront-app/pages/Storyblok.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93xD3Rcww2zSMeta?.name ?? "slug",
    path: _91_46_46_46slug_93xD3Rcww2zSMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93xD3Rcww2zSMeta || {},
    alias: _91_46_46_46slug_93xD3Rcww2zSMeta?.alias || [],
    redirect: _91_46_46_46slug_93xD3Rcww2zSMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: about_45us2uZmKFvXwfMeta?.name ?? "About-about-us",
    path: about_45us2uZmKFvXwfMeta?.path ?? "/About/about-us",
    meta: about_45us2uZmKFvXwfMeta || {},
    alias: about_45us2uZmKFvXwfMeta?.alias || [],
    redirect: about_45us2uZmKFvXwfMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/About/about-us.vue").then(m => m.default || m)
  },
  {
    name: index3aDaS72uKjMeta?.name ?? "About",
    path: index3aDaS72uKjMeta?.path ?? "/About",
    meta: index3aDaS72uKjMeta || {},
    alias: index3aDaS72uKjMeta?.alias || [],
    redirect: index3aDaS72uKjMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/About/index.vue").then(m => m.default || m)
  },
  {
    name: index32ZbzGF4msMeta?.name ?? "apply-for-credit",
    path: index32ZbzGF4msMeta?.path ?? "/apply-for-credit",
    meta: index32ZbzGF4msMeta || {},
    alias: index32ZbzGF4msMeta?.alias || [],
    redirect: index32ZbzGF4msMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/apply-for-credit/index.vue").then(m => m.default || m)
  },
  {
    name: indexTGI0avfK6hMeta?.name ?? "apply-for-credit-success",
    path: indexTGI0avfK6hMeta?.path ?? "/apply-for-credit/success",
    meta: indexTGI0avfK6hMeta || {},
    alias: indexTGI0avfK6hMeta?.alias || [],
    redirect: indexTGI0avfK6hMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/apply-for-credit/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93v8xd2Mf9JVMeta?.name ?? "Blog-topic-slug",
    path: _91slug_93v8xd2Mf9JVMeta?.path ?? "/Blog/:topic()/:slug()",
    meta: _91slug_93v8xd2Mf9JVMeta || {},
    alias: _91slug_93v8xd2Mf9JVMeta?.alias || [],
    redirect: _91slug_93v8xd2Mf9JVMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Blog/[topic]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexOiLFCWrkUDMeta?.name ?? "Blog-topic",
    path: indexOiLFCWrkUDMeta?.path ?? "/Blog/:topic()",
    meta: indexOiLFCWrkUDMeta || {},
    alias: indexOiLFCWrkUDMeta?.alias || [],
    redirect: indexOiLFCWrkUDMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Blog/[topic]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkrUyonzpRoMeta?.name ?? "Blog",
    path: indexkrUyonzpRoMeta?.path ?? "/Blog",
    meta: indexkrUyonzpRoMeta || {},
    alias: indexkrUyonzpRoMeta?.alias || [],
    redirect: indexkrUyonzpRoMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91branch_93fFXCKzmuqoMeta?.name ?? "Branches-branch",
    path: _91branch_93fFXCKzmuqoMeta?.path ?? "/Branches/:branch()",
    meta: _91branch_93fFXCKzmuqoMeta || {},
    alias: _91branch_93fFXCKzmuqoMeta?.alias || [],
    redirect: _91branch_93fFXCKzmuqoMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Branches/[branch].vue").then(m => m.default || m)
  },
  {
    name: indexEHc7b21lkhMeta?.name ?? "Branches",
    path: indexEHc7b21lkhMeta?.path ?? "/Branches",
    meta: indexEHc7b21lkhMeta || {},
    alias: indexEHc7b21lkhMeta?.alias || [],
    redirect: indexEHc7b21lkhMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Branches/index.vue").then(m => m.default || m)
  },
  {
    name: indexAeHmek2PKeMeta?.name ?? "Brochures",
    path: indexAeHmek2PKeMeta?.path ?? "/Brochures",
    meta: indexAeHmek2PKeMeta || {},
    alias: indexAeHmek2PKeMeta?.alias || [],
    redirect: indexAeHmek2PKeMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Brochures/index.vue").then(m => m.default || m)
  },
  {
    name: _91requestId_93zQ0e4zMf54Meta?.name ?? "Brochures-Requests-requestId",
    path: _91requestId_93zQ0e4zMf54Meta?.path ?? "/Brochures/Requests/:requestId()",
    meta: _91requestId_93zQ0e4zMf54Meta || {},
    alias: _91requestId_93zQ0e4zMf54Meta?.alias || [],
    redirect: _91requestId_93zQ0e4zMf54Meta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Brochures/Requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: indexeRJavjpxiCMeta?.name ?? "Careers-info",
    path: indexeRJavjpxiCMeta?.path ?? "/Careers/:info()",
    meta: indexeRJavjpxiCMeta || {},
    alias: indexeRJavjpxiCMeta?.alias || [],
    redirect: indexeRJavjpxiCMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Careers/[info]/index.vue").then(m => m.default || m)
  },
  {
    name: indexykxhfFYXLFMeta?.name ?? "Careers",
    path: indexykxhfFYXLFMeta?.path ?? "/Careers",
    meta: indexykxhfFYXLFMeta || {},
    alias: indexykxhfFYXLFMeta?.alias || [],
    redirect: indexykxhfFYXLFMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexCt1iAG3hPIMeta?.name ?? "Careers-vacancies-department-role",
    path: indexCt1iAG3hPIMeta?.path ?? "/Careers/vacancies/:department()/:role()",
    meta: indexCt1iAG3hPIMeta || {},
    alias: indexCt1iAG3hPIMeta?.alias || [],
    redirect: indexCt1iAG3hPIMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Careers/vacancies/[department]/[role]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMVnVfMawWfMeta?.name ?? "Careers-vacancies-department",
    path: indexMVnVfMawWfMeta?.path ?? "/Careers/vacancies/:department()",
    meta: indexMVnVfMawWfMeta || {},
    alias: indexMVnVfMawWfMeta?.alias || [],
    redirect: indexMVnVfMawWfMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Careers/vacancies/[department]/index.vue").then(m => m.default || m)
  },
  {
    name: indexaRW7DFzp0ZMeta?.name ?? "Careers-vacancies",
    path: indexaRW7DFzp0ZMeta?.path ?? "/Careers/vacancies",
    meta: indexaRW7DFzp0ZMeta || {},
    alias: indexaRW7DFzp0ZMeta?.alias || [],
    redirect: indexaRW7DFzp0ZMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Careers/vacancies/index.vue").then(m => m.default || m)
  },
  {
    name: indexPdo03gPiogMeta?.name ?? "Cart",
    path: indexPdo03gPiogMeta?.path ?? "/Cart",
    meta: indexPdo03gPiogMeta || {},
    alias: indexPdo03gPiogMeta?.alias || [],
    redirect: indexPdo03gPiogMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexOjFAU2XZohMeta?.name ?? "category-cat1-cat2-cat3",
    path: indexOjFAU2XZohMeta?.path ?? "/category/:cat1?/:cat2?/:cat3?",
    meta: indexOjFAU2XZohMeta || {},
    alias: indexOjFAU2XZohMeta?.alias || [],
    redirect: indexOjFAU2XZohMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/category/[[cat1]]/[[cat2]]/[[cat3]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKHZ8eDdYJuMeta?.name ?? "checkout-login",
    path: indexKHZ8eDdYJuMeta?.path ?? "/checkout-login",
    meta: indexKHZ8eDdYJuMeta || {},
    alias: indexKHZ8eDdYJuMeta?.alias || [],
    redirect: indexKHZ8eDdYJuMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/checkout-login/index.vue").then(m => m.default || m)
  },
  {
    name: indexCs9FeokLYqMeta?.name ?? "create-account",
    path: indexCs9FeokLYqMeta?.path ?? "/create-account",
    meta: indexCs9FeokLYqMeta || {},
    alias: indexCs9FeokLYqMeta?.alias || [],
    redirect: indexCs9FeokLYqMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/create-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexnL5cYa8agqMeta?.name ?? "create-account-success",
    path: indexnL5cYa8agqMeta?.path ?? "/create-account/success",
    meta: indexnL5cYa8agqMeta || {},
    alias: indexnL5cYa8agqMeta?.alias || [],
    redirect: indexnL5cYa8agqMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/create-account/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91topic_939vWWQxx86JMeta?.name ?? "explore-topic",
    path: _91topic_939vWWQxx86JMeta?.path ?? "/explore/:topic()",
    meta: _91topic_939vWWQxx86JMeta || {},
    alias: _91topic_939vWWQxx86JMeta?.alias || [],
    redirect: _91topic_939vWWQxx86JMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/explore/[topic].vue").then(m => m.default || m)
  },
  {
    name: indexja73ISUa7gMeta?.name ?? "forgotten-password",
    path: indexja73ISUa7gMeta?.path ?? "/forgotten-password",
    meta: indexja73ISUa7gMeta || {},
    alias: indexja73ISUa7gMeta?.alias || [],
    redirect: indexja73ISUa7gMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/forgotten-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexUZZHIvdXFhMeta?.name ?? "get-mobile-app",
    path: indexUZZHIvdXFhMeta?.path ?? "/get-mobile-app",
    meta: indexUZZHIvdXFhMeta || {},
    alias: indexUZZHIvdXFhMeta?.alias || [],
    redirect: indexUZZHIvdXFhMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/get-mobile-app/index.vue").then(m => m.default || m)
  },
  {
    name: index2DfmuZJBvvMeta?.name ?? "Kitchens-folder-name",
    path: index2DfmuZJBvvMeta?.path ?? "/Kitchens/:folder()/:name(.*)*",
    meta: index2DfmuZJBvvMeta || {},
    alias: index2DfmuZJBvvMeta?.alias || [],
    redirect: index2DfmuZJBvvMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Kitchens/[folder]/[...name]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHP3x6eoAngMeta?.name ?? "Kitchens-folder",
    path: indexHP3x6eoAngMeta?.path ?? "/Kitchens/:folder()",
    meta: indexHP3x6eoAngMeta || {},
    alias: indexHP3x6eoAngMeta?.alias || [],
    redirect: indexHP3x6eoAngMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Kitchens/[folder]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFyGjUbKHzZMeta?.name ?? "Kitchens",
    path: indexFyGjUbKHzZMeta?.path ?? "/Kitchens",
    meta: indexFyGjUbKHzZMeta || {},
    alias: indexFyGjUbKHzZMeta?.alias || [],
    redirect: indexFyGjUbKHzZMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Kitchens/index.vue").then(m => m.default || m)
  },
  {
    name: indexedZrmX2wEUMeta?.name ?? "Login",
    path: indexedZrmX2wEUMeta?.path ?? "/Login",
    meta: indexedZrmX2wEUMeta || {},
    alias: indexedZrmX2wEUMeta?.alias || [],
    redirect: indexedZrmX2wEUMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Login/index.vue").then(m => m.default || m)
  },
  {
    name: indexonTZWhuKYjMeta?.name ?? "my-account-address-book",
    path: indexonTZWhuKYjMeta?.path ?? "/my-account/address-book",
    meta: indexonTZWhuKYjMeta || {},
    alias: indexonTZWhuKYjMeta?.alias || [],
    redirect: indexonTZWhuKYjMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/address-book/index.vue").then(m => m.default || m)
  },
  {
    name: index1neI4BGFR1Meta?.name ?? "my-account-financial-bank-redirect",
    path: index1neI4BGFR1Meta?.path ?? "/my-account/financial/bank/redirect",
    meta: index1neI4BGFR1Meta || {},
    alias: index1neI4BGFR1Meta?.alias || [],
    redirect: index1neI4BGFR1Meta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/financial/bank/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: indexrWERJce7WHMeta?.name ?? "my-account-financial",
    path: indexrWERJce7WHMeta?.path ?? "/my-account/financial",
    meta: indexrWERJce7WHMeta || {},
    alias: indexrWERJce7WHMeta?.alias || [],
    redirect: indexrWERJce7WHMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/financial/index.vue").then(m => m.default || m)
  },
  {
    name: index5GZFt9YPIaMeta?.name ?? "my-account-financial-pay-failed",
    path: index5GZFt9YPIaMeta?.path ?? "/my-account/financial/pay/failed",
    meta: index5GZFt9YPIaMeta || {},
    alias: index5GZFt9YPIaMeta?.alias || [],
    redirect: index5GZFt9YPIaMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/financial/pay/failed/index.vue").then(m => m.default || m)
  },
  {
    name: indexrilCgyJMHmMeta?.name ?? "my-account-financial-pay",
    path: indexrilCgyJMHmMeta?.path ?? "/my-account/financial/pay",
    meta: indexrilCgyJMHmMeta || {},
    alias: indexrilCgyJMHmMeta?.alias || [],
    redirect: indexrilCgyJMHmMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/financial/pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexSTvOwujGMhMeta?.name ?? "my-account-financial-pay-redirect",
    path: indexSTvOwujGMhMeta?.path ?? "/my-account/financial/pay/redirect",
    meta: indexSTvOwujGMhMeta || {},
    alias: indexSTvOwujGMhMeta?.alias || [],
    redirect: indexSTvOwujGMhMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/financial/pay/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: indexrBGzxJWQiuMeta?.name ?? "my-account-financial-pay-thank-you",
    path: indexrBGzxJWQiuMeta?.path ?? "/my-account/financial/pay/thank-you",
    meta: indexrBGzxJWQiuMeta || {},
    alias: indexrBGzxJWQiuMeta?.alias || [],
    redirect: indexrBGzxJWQiuMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/financial/pay/thank-you/index.vue").then(m => m.default || m)
  },
  {
    name: indexcEKrbvOW9zMeta?.name ?? "my-account",
    path: indexcEKrbvOW9zMeta?.path ?? "/my-account",
    meta: indexcEKrbvOW9zMeta || {},
    alias: indexcEKrbvOW9zMeta?.alias || [],
    redirect: indexcEKrbvOW9zMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexOQQso2pygwMeta?.name ?? "my-account-manage-users",
    path: indexOQQso2pygwMeta?.path ?? "/my-account/manage-users",
    meta: indexOQQso2pygwMeta || {},
    alias: indexOQQso2pygwMeta?.alias || [],
    redirect: indexOQQso2pygwMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/manage-users/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MGejmAvl7ZMeta?.name ?? "my-account-orders-slug",
    path: _91slug_93MGejmAvl7ZMeta?.path ?? "/my-account/orders/:slug()",
    meta: _91slug_93MGejmAvl7ZMeta || {},
    alias: _91slug_93MGejmAvl7ZMeta?.alias || [],
    redirect: _91slug_93MGejmAvl7ZMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/orders/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexxHs7H82iYXMeta?.name ?? "my-account-orders",
    path: indexxHs7H82iYXMeta?.path ?? "/my-account/orders",
    meta: indexxHs7H82iYXMeta || {},
    alias: indexxHs7H82iYXMeta?.alias || [],
    redirect: indexxHs7H82iYXMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/my-account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91_token_93wnF2nCkxdoMeta?.name ?? "order-confirmation-_token",
    path: _91_token_93wnF2nCkxdoMeta?.path ?? "/order-confirmation/:_token()",
    meta: _91_token_93wnF2nCkxdoMeta || {},
    alias: _91_token_93wnF2nCkxdoMeta?.alias || [],
    redirect: _91_token_93wnF2nCkxdoMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/order-confirmation/[_token].vue").then(m => m.default || m)
  },
  {
    name: indexqMIFbvH9VzMeta?.name ?? "Pay",
    path: indexqMIFbvH9VzMeta?.path ?? "/Pay",
    meta: indexqMIFbvH9VzMeta || {},
    alias: indexqMIFbvH9VzMeta?.alias || [],
    redirect: indexqMIFbvH9VzMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Pay/index.vue").then(m => m.default || m)
  },
  {
    name: _91productHandle_93uWKpkgCB1FMeta?.name ?? "product-productHandle",
    path: _91productHandle_93uWKpkgCB1FMeta?.path ?? "/product/:productHandle()",
    meta: _91productHandle_93uWKpkgCB1FMeta || {},
    alias: _91productHandle_93uWKpkgCB1FMeta?.alias || [],
    redirect: _91productHandle_93uWKpkgCB1FMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/product/[productHandle].vue").then(m => m.default || m)
  },
  {
    name: indexjtH4pz6xWLMeta?.name ?? "reset-password",
    path: indexjtH4pz6xWLMeta?.path ?? "/reset-password",
    meta: indexjtH4pz6xWLMeta || {},
    alias: indexjtH4pz6xWLMeta?.alias || [],
    redirect: indexjtH4pz6xWLMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexTkUHtq1kRrMeta?.name ?? "set-password",
    path: indexTkUHtq1kRrMeta?.path ?? "/set-password",
    meta: indexTkUHtq1kRrMeta || {},
    alias: indexTkUHtq1kRrMeta?.alias || [],
    redirect: indexTkUHtq1kRrMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/set-password/index.vue").then(m => m.default || m)
  },
  {
    name: StoryblokU1DVT196ENMeta?.name ?? "Storyblok",
    path: StoryblokU1DVT196ENMeta?.path ?? "/Storyblok",
    meta: StoryblokU1DVT196ENMeta || {},
    alias: StoryblokU1DVT196ENMeta?.alias || [],
    redirect: StoryblokU1DVT196ENMeta?.redirect,
    component: () => import("/var/www/apps/storefront-app/pages/Storyblok.vue").then(m => m.default || m)
  }
]