<template>
  <component :is="tag" :class="stackClasses" :data-test-id="dataTestId">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { StackProps } from "./types";
import { computed } from "vue";

const props = withDefaults(defineProps<StackProps>(), {
  tag: "div",
  align: "start",
  gap: "sm",
  direction: "row",
  justify: "start",
});

const responsiveClass = (
  propObject: Record<string, string>,
  twClass: string,
) => {
  return Object.entries(propObject).map(
    ([breakpoint, value]) => `${breakpoint}:${twClass}${value}`,
  );
};

const stackClasses = computed(() => {
  return [
    "ui-flex",
    `ui-flex-${props.direction}`,
    `ui-items-${props.align}`,
    `ui-justify-${props.justify}`,
    `ui-gap-${props.gap}`,
    responsiveClass(props.breakpoints?.direction || {}, "ui-flex-"),
    responsiveClass(props.breakpoints?.justify || {}, "ui-justify-"),
    responsiveClass(props.breakpoints?.align || {}, "ui-items-"),
    responsiveClass(props.breakpoints?.gap || {}, "ui-gap-"),
  ];
});
</script>
